import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "./CertificateDetails.css";

const CertificateDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [certificates, setCertificates] = useState([]);
  const [error, setError] = useState(null);

  // Extract certificate IDs from the query string
  const query = new URLSearchParams(location.search);
  const idsQuery = query.get("ids");

  useEffect(() => {
    const fetchCertificates = async () => {
      if (!idsQuery) {
        setError("No certificate IDs provided.");
        return;
      }
  
      try {
        const idsArray = idsQuery.split(",");
        console.log("Fetching certificates for IDs:", idsArray);  // Debugging
  
        const response = await axios.post(
          "https://backend-51k4.onrender.com/api/certificates/verify",
          { certificateIDs: idsArray }
        );
  
        console.log("Response data:", response.data);  // Debugging
  
        if (Array.isArray(response.data) && response.data.length > 0) {
          setCertificates(response.data);
        } else {
          setError("No certificates found for the provided IDs.");
        }
      } catch (error) {
        console.error("Error fetching certificate data:", error);
        setError("Error fetching certificate data. Please try again.");
      }
    };
  
    fetchCertificates();
  }, [idsQuery]);
  

  return (
    <div className="certificate-details-container">
      <h2>Certificate Details</h2>

      {/* Display an error message if any */}
      {error && <p className="error-message">{error}</p>}

      {/* Display certificates if available */}
      {certificates.length > 0 ? (
        certificates.map((certificate, index) => (
          <div key={index} className="certificate-card">
            <p>
              <strong>Certificate ID:</strong> {certificate.certificateID}
            </p>
            <p>
              <strong>Student Name:</strong> {certificate.studentName}
            </p>
            <p>
              <strong>Registration No:</strong> {certificate.regNo}
            </p>
            <p>
              <strong>College:</strong> {certificate.college}
            </p>
            <p>
              <strong>Course Name:</strong> {certificate.courseName}
            </p>
            <p>
              <strong>Marks Obtained:</strong> {certificate.marks}
            </p>
            <p>
              <strong>Issued:</strong>{" "}
              {new Date(certificate.issueDate).toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "long",
                year: "numeric",
              })}
            </p>
          </div>
        ))
      ) : (
        !error && <p>loading............</p>
      )}

      {/* Back button */}
      <button className="back-btn" onClick={() => navigate("/certificate-verification")}>
        Go Back
      </button>



        {/* Circular Images Section */}
     <section className="circular-images-section">
        <h2>Registered</h2> {/* Optional heading */}
        <div className="circular-images-container">
          <div className="circular-image">
            <img src="images/AICTE.jpg" alt="AICTE" />
          </div>
          <div className="circular-image">
            <img src="images/MCA.jpg" alt="MCA" />
          </div>
          <div className="circular-image">
            <img src="images/BIHAR.jpg" alt="BIHAR STARTUP" />
          </div>
          <div className="circular-image">
            <img src="images/ISO.jpg" alt="ISO" />
          </div>
          <div className="circular-image">
            <img src="images/MSME.jpg" alt="MSME" />
          </div>
          {/* Add more circular images as needed */}
        </div>
      </section>
    </div>
  );
};

export default CertificateDetails;
