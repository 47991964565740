import React from 'react';
import './AboutUs.css';
import { Helmet } from 'react-helmet';
import { FaRocket, FaGlobe, FaGraduationCap, FaUsers, FaLightbulb } from 'react-icons/fa';

// Sample data for team members
const teamMembers = [
  { name: 'Mayank Jha', role: 'Software Developer', imageUrl: 'images/Mayank.WebP', bio: 'Mayank is a passionate software developer with expertise in full-stack development and cloud technologies. He loves solving complex problems and building scalable applications.' },
  { name: 'Ankit Pandey', role: 'Architect', imageUrl: 'images/Ankitt.jpg', bio: 'Ankit is a seasoned architect with over 10 years of experience in designing robust and scalable systems. He specializes in microservices and cloud-native architectures.' },
  { name: 'Manvi Verma', role: 'Java Developer', imageUrl: 'images/manvi.WebP', bio: 'Manvi is a Java expert with a strong background in backend development. She is passionate about teaching and mentoring aspiring developers.' },
  { name: 'Harsh Verma', role: 'Quantity Surveyor', imageUrl: 'images/Harsh.WebP', bio: 'Harsh is a skilled quantity surveyor with extensive experience in construction project management. He ensures projects are delivered on time and within budget.' },
  { name: 'Madhu Kumari', role: 'Recruitment Specialist', imageUrl: 'images/madhu.jpg', bio: 'Madhu is a recruitment specialist with a knack for identifying top talent. She is dedicated to helping students and professionals find their dream jobs.' },
];

// Timeline data
const timelineData = [
  { year: '2018', event: 'InternCourse Founded', description: 'InternCourse was established with a vision to revolutionize online education.' },
  { year: '2019', event: 'First Course Launched', description: 'We launched our first course in software development, receiving overwhelming feedback.' },
  { year: '2020', event: 'Global Expansion', description: 'Expanded our reach to over 50 countries, serving thousands of learners worldwide.' },
  { year: '2021', event: 'Award-Winning Platform', description: 'Recognized as the Best Online Learning Platform by EdTech Awards.' },
  { year: '2022', event: 'AI-Powered Learning', description: 'Integrated AI-driven personalized learning paths for students.' },
];

const AboutUs = () => {
  return (
    <div className="about-us">
      {/* SEO and Meta Tags */}
      <Helmet>
        <title>About Us - InternCourse: Your Online Learning Platform</title>
        <meta
          name="description"
          content="Learn more about InternCourse, a leading online learning platform. Discover our mission, vision, team, and how we deliver high-quality, affordable courses to students worldwide."
        />
        <meta
          name="keywords"
          content="InternCourse, online learning, e-learning, online courses, software development courses, Java development, digital learning, affordable education"
        />
        <meta property="og:title" content="About InternCourse - Revolutionizing Online Education" />
        <meta
          property="og:description"
          content="Discover InternCourse, the leading platform offering affordable online courses in various fields like software development, design, and more. Meet our team and learn about our vision."
        />
        <meta property="og:image" content="https://interncourse.online/images/about-image.jpg" />
        <meta property="og:url" content="https://interncourse.online/about" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="InternCourse" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About InternCourse | Affordable Online Learning" />
        <meta
          name="twitter:description"
          content="Learn about InternCourse, an innovative platform offering top-notch online courses in software development and more."
        />
        <meta name="twitter:image" content="https://interncourse.online/images/about-image.jpg" />
        <meta name="twitter:site" content="@InternCourse" />
        <link rel="canonical" href="https://interncourse.online/about" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "InternCourse",
              "url": "https://interncourse.online/about",
              "logo": "https://interncourse.online/images/logo.png",
              "sameAs": [
                "https://www.facebook.com/InternCourse",
                "https://www.instagram.com/InternCourse",
                "https://twitter.com/InternCourse",
                "https://www.linkedin.com/company/interncourse"
              ],
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9771041512",
                "contactType": "customer service",
                "areaServed": "IN",
                "availableLanguage": "en"
              },
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Your Street Address",
                "addressLocality": "City",
                "addressRegion": "State",
                "postalCode": "ZIP Code",
                "addressCountry": "IN"
              }
            }
          `}
        </script>
      </Helmet>

    

      {/* Mission and Vision Section */}
      <section className="mission-vision">
        <div className="container">
          <div className="mission-vision-grid">
            <div className="mission-vision-card">
              <FaRocket className="icon" />
              <h2>Our Mission</h2>
              <p>
                To democratize education by offering affordable, high-quality courses that cater to
                learners of all skill levels. We aim to create an engaging and supportive learning
                environment.
              </p>
            </div>
            <div className="mission-vision-card">
              <FaGlobe className="icon" />
              <h2>Our Vision</h2>
              <p>
                To revolutionize online education by breaking down barriers and making learning
                accessible to everyone, everywhere.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Values Section */}
      <section className="values">
        <div className="container">
          <h2>Our Core Values</h2>
          <div className="values-grid">
            <div className="value-card">
              <FaGraduationCap className="icon" />
              <h3>Quality Education</h3>
              <p>
                We are committed to delivering high-quality content that meets the needs of our
                learners.
              </p>
            </div>
            <div className="value-card">
              <FaUsers className="icon" />
              <h3>Student-Centric</h3>
              <p>
                Our learners are at the heart of everything we do. We prioritize their success and
                growth.
              </p>
            </div>
            <div className="value-card">
              <FaLightbulb className="icon" />
              <h3>Innovation</h3>
              <p>
                We constantly innovate to provide the best learning experience using the latest
                technologies.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Timeline Section */}
      <section className="timeline">
        <div className="container">
          <h2>Our Journey</h2>
          <div className="timeline-grid">
            {timelineData.map((item, index) => (
              <div className="timeline-card" key={index}>
                <div className="timeline-year">{item.year}</div>
                <div className="timeline-content">
                  <h3>{item.event}</h3>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Team Section */}
      <section className="team">
        <div className="container">
          <h2>Meet Our Team</h2>
          <div className="team-grid">
            {teamMembers.map((member, index) => (
              <div className="team-member" key={index}>
                <div className="team-member-photo">
                  <img src={member.imageUrl} alt={member.name} />
                </div>
                <div className="team-member-info">
                  <h3>{member.name}</h3>
                  <p>{member.role}</p>
                  <p className="team-member-bio">{member.bio}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

    
    </div>
  );
};

export default AboutUs;