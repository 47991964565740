import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import "./CertificateVerification.css";

const CertificateVerification = () => {
  // State management
  const [certificateID, setCertificateID] = useState(""); // Single certificate ID
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  // Handle the verification process
  const handleVerify = async () => {
    setError(null); // Clear previous errors

    if (!certificateID.trim()) {
      setError("Please enter a valid certificate ID.");
      return;
    }

    setLoading(true);

    try {
      // Make API request to verify the single certificate
      const response = await axios.post(
        "https://backend-51k4.onrender.com/api/certificates/verify",
        { certificateIDs: [certificateID.trim()] }
      );

      // If certificate is found, navigate to details page
      if (response.data && response.data.length > 0) {
        navigate(`/details?ids=${certificateID}`, {
          state: { certificates: response.data },
        });
      } else {
        setError(`No certificate found with the ID: ${certificateID}`);
      }
    } catch (error) {
      console.error("Error verifying certificate:", error);
      setError("An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="certificate-verification-container">






{/* SEO Meta Tags */}
<Helmet>
        <title>InternCourse - Certificate Verification</title>
        <meta
          name="description"
          content="Verify your InternCourse Internship certificate details by entering the certificate ID. Check the authenticity of your certificate online."
        />
        <meta
          name="keywords"
          content="certificate verification, verify certificate, check certificate, online certificate verification, InternCourse, InternCourse Online, internship"
        />
        <meta name="author" content="InternCourse" />
        <meta property="og:title" content="Certificate Verification" />
        <meta
          property="og:description"
          content="Verify your certificate details by entering the certificate ID."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://interncourse.online/certificate-verification" />
        <meta property="og:image" content="https://interncourse.online/images/logo.png" />
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "Certificate Verification",
            "description": "Verify your InternCourse Internship certificate details by entering the certificate ID.",
            "url": "https://interncourse.online/certificate-verification",
            "image": "https://interncourse.online/images/logo.png"
          }
          `}
        </script>
      </Helmet>






      <h1 className="heading">Certificate Verification</h1>
      <p className="description">
        Enter a certificate ID below to verify its details.
      </p>

      <input
        type="text"
        placeholder="Enter Certificate ID"
        value={certificateID}
        onChange={(e) => setCertificateID(e.target.value)}
        className="certificate-input"
        disabled={loading} // Disable input while verifying
      />

      <button
        onClick={handleVerify}
        disabled={loading || !certificateID.trim()} // Disable button if input is empty
        className={`verify-btn ${loading ? "loading" : ""}`}
      >
        {loading ? "Verifying..." : "Verify Certificate"}
      </button>

      {error && <p className="error-message">{error}</p>}
   
   
     {/* Circular Images Section */}
     <section className="circular-images-section">
        <h2>Registered</h2> {/* Optional heading */}
        <div className="circular-images-container">
          <div className="circular-image">
            <img src="images/AICTE.jpg" alt="AICTE" />
          </div>
          <div className="circular-image">
            <img src="images/MCA.jpg" alt="MCA" />
          </div>
          <div className="circular-image">
            <img src="images/BIHAR.jpg" alt="BIHAR STARTUP" />
          </div>

          <div className="circular-image"> <img src="images/startupind.png" alt="STARTUP INDIA" /> </div>
          <div className="circular-image">
            <img src="images/ISO.jpg" alt="ISO" />
          </div>
          <div className="circular-image">
            <img src="images/MSME.jpg" alt="MSME" />
          </div>
          {/* Add more circular images as needed */}
        </div>
      </section>



      {/* FAQ Section */}
      <section className="faq-section">
        <h2>Frequently Asked Questions</h2>
        <div className="faq">
          <h3>How do I verify my certificate?</h3>
          <p>Enter your certificate ID in the input field above and click "Verify Certificate."</p>
        </div>
        <div className="faq">
          <h3>What if my certificate is not found?</h3>
          <p>If your certificate is not found, please double-check the ID or contact support.</p>
        </div>
      </section>


   
   
    </div>

  );
};

export default CertificateVerification;
